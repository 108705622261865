export default {
  overview: {
    title: 'Accommodation portal',
    showAll: 'Toon alles',
    select: 'Selecteren',
    search: 'Zoeken',

    table: {
      id: 'id',
      accommodationName: 'Accommodatie',
      units: 'Units',
    },
  },

  detail: {
    delete: 'Verwijder',
    confirmDelete: 'Weet je zeker dat je deze periode wilt verwijderen?',
    confirmDeleteTitle: 'Verwijder boeking',
    invalidDate: 'Geen valide selectie',
    addBooking: 'Boeking toevoegen',
    block: 'Block',
    comment: 'Comment',
    start: 'Startdatum: ',
    end: 'Einddatum: ',
    periodUpdated: 'Periode bijgewerkt',
  },
};
