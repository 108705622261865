<template>
  <div
    class="DestinationsList"
    ref="destinationsRef"
  >
    <div
      v-show="isMobile && !showRowOne"
      class="menuItem"
      @click="backOneStep"
    >
      <q-icon name="icon-chevron-left" />
      Terug
    </div>

    <div v-show="showRowOne">
      <div class="category">Bestemmingen</div>
      <LayoutDestinationItem
        v-for="subItem in destinationsLevel2"
        :key="subItem.name"
        :text="subItem.name"
        :hasSubItems="true"
        :active="selectedItem1Id === subItem.idx"
        @click="selectedItem1Id = subItem.idx"
      />
    </div>

    <div
      v-if="showRowTwo"
      v-for="(group, groupIdx) in destinationsLevel3"
      :style="{
        display: groupIdx + 1 === selectedItem1Id ? 'flex' : 'none',
      }"
      class="column"
      :class="{ hideItems }"
    >
      <div class="category">{{ item1Name }}</div>
      <LayoutDestinationItem
        v-for="(subItem, idx) in group"
        :key="subItem.name"
        :text="subItem.name"
        :hasSubItems="destinationHasChildren(subItem)"
        :active="selectedItem2Id == subItem.id"
        @click="selectedItem2Id = subItem.id"
      />

      <div
        v-if="hideItems"
        @click="hideItems = !hideItems"
        class="category"
        id="showAllButton"
      >
        Bekijk all {{ item1Name?.toLowerCase() }}
      </div>
    </div>

    <div
      class="column"
      v-if="showRowThree"
    >
      <div class="category">{{ item2Name }}</div>
      <LayoutDestinationItem
        v-for="subItem in destinationsLandingPages"
        :key="subItem.id"
        :text="subItem.title"
        :hasSubItems="!!subItem.children"
        :link="subItem.url"
        :style="{ display: selectedItem2Id == subItem.sitemapId ? 'flex' : 'none' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useElocusStore from '@/store/elocus';

const emit = defineEmits(['open-state', 'clicked-outside']);
const elocusStore = useElocusStore();
const router = useRouter();

const hideItems = ref(true);
const destinationsRef = ref();
const destinations = ref({});
const navigationResponse = (await fetchData('/api/navigation')) as ElocusNavigationItem[];
const destinationsLandingPages = elocusStore.destinations;
mapNavigation(navigationResponse);

const selectedItem1Id = ref(null);
const selectedItem2Id = ref(null);

const item1Name = computed(() => (selectedItem1Id.value ? destinations.value?.children[selectedItem1Id.value - 1]?.name : ''));
const item2Name = computed(() =>
  selectedItem1Id.value
    ? destinations.value?.children[selectedItem1Id.value - 1]?.children.find((child) => child.id === selectedItem2Id.value)?.name
    : ''
);

const { destinationsLevel2, destinationsLevel3 } = menuItemsHandler();
const { showRowOne, showRowTwo, showRowThree, backOneStep, isMobile } = mobileHandling();

function destinationHasChildren(destination: ElocusNavigationItem) {
  return destinationsLandingPages.findIndex((dest) => dest.sitemapId === destination.id) > -1;
}

function mapNavigation(navigation: ElocusNavigationItem[]) {
  const destinationsList = navigation.find((nav) => nav.id === 2267);
  if (!destinationsList || !destinationsList.children?.length) return;

  destinations.value = destinationsList;
}

onMounted(() => {
  if (!isMobile.value) {
    window.addEventListener('click', (event) => {
      if (!destinationsRef.value?.contains(event.target) && event.target?.id !== 'showAllButton') {
        emit('clicked-outside');
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  selectedItem1Id.value = null;
  selectedItem2Id.value = null;
  emit('clicked-outside');
  next();
});

function menuItemsHandler() {
  const destinationsLevel2 = computed(() =>
    destinations.value?.children.map((child, childIdx) => ({ name: child.name, idx: childIdx + 1 }))
  );
  const destinationsLevel3 = computed(() => destinations.value?.children.map((child) => ({ ...child.children })));

  return {
    destinationsLevel2,
    destinationsLevel3,
  };
}

function mobileHandling() {
  const isMobile = getIsMobile();
  const showRowOne = computed(() => (isMobile.value ? selectedItem1Id.value == null && !selectedItem2Id.value : true));
  const showRowTwo = computed(() => (isMobile.value ? selectedItem1Id.value != null && !selectedItem2Id.value : true));
  const showRowThree = computed(() => (isMobile.value ? selectedItem2Id.value : true));

  function backOneStep() {
    if (selectedItem2Id.value) {
      selectedItem2Id.value = null;
    } else if (selectedItem1Id.value) {
      selectedItem1Id.value = null;
    }
  }

  watch(
    () => selectedItem1Id.value,
    (val) => {
      hideItems.value = true;
      emit('open-state', val);
    }
  );

  watch(
    selectedItem1Id,
    (val) => {
      selectedItem2Id.value = null;
    },
    { deep: true }
  );

  return {
    isMobile,
    showRowOne,
    showRowTwo,
    showRowThree,
    backOneStep,
  };
}
</script>

<style lang="scss" scoped>
.DestinationsList {
  // width: 560px;
  background: white;
  border-radius: 24px;
  display: flex;
  gap: 20px;

  padding: 24px;
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(55, 55, 55, 0.2);

  .hideItems .DestinationItem:nth-child(n + 6) {
    display: none;
  }
}

.category {
  font-weight: bolder;
  padding: 12px 16px;
}

@media (max-width: 1000px) {
  .DestinationsList {
    padding: 0;
    box-shadow: none;
    flex-direction: column;
    font-size: 20px;
    display: block;
    width: 100%;
  }

  .menuItem {
    padding: 12px;
  }
}
</style>
