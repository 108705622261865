<template>
  <div>
    <noscript v-if="!isDev"
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-NTWGKMLZ"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>

    <LayoutHeader />
    <NuxtLoadingIndicator color="#D6F1A9" />

    <NuxtLayout class="layout">
      <main class="main">
        <NuxtPage :class="{ isAdmin }" />
      </main>
    </NuxtLayout>
    <!-- Prod has a cookie wall via cookiebot, on dev we can use it for debugging. -->
    <LayoutCookieNotification v-if="isDev" />
    <LayoutShoppingCartItemAddedDialog />
    <LayoutFooter class="q-mt-xl" />
    <AuthLoginFlowModal
      v-if="showAuthModal"
      v-model="showAuthModal"
    />
  </div>
</template>

<script setup lang="ts">
import { Screen } from 'quasar';
import { computed, watch } from 'vue';
import useStore from '~/store/store';
import useAuthStore from '~/store/auth';
import { USER_LEVELS } from './config';
import { i18n } from '~/plugins/i18n';

const route = useRoute();
const store = useStore();
const authStore = useAuthStore();
const showAuthModal = computed(() => authStore.showLoginModal);
const isAdmin = computed(() => authStore.user?.isAdmin || false);
const isDev = useRuntimeConfig().public.isDev;

const isMobile = computed(() => Screen.width < 600);
const user = computed(() => authStore.user);

// set auth store bij binnenkomst (niet available in plugin)
onMounted(() => {
  if (authStore.user) {
    const { $sentrySetUser } = useNuxtApp();
    $sentrySetUser(authStore.user);
  }
  watch(
    user,
    (newUser, oldUser) => {
      if (!oldUser?.email && newUser?.email && newUser.userLevel != USER_LEVELS.Booking) {
        store.getFavorite();
      }
      if (newUser.isAdmin) {
        store.getVersion();
      }
    },
    { immediate: true }
  );
});

watch(
  isMobile,
  (value) => {
    store.setIsMobile(value);
  },
  { immediate: true }
);

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: import.meta.env.VITE_SITE_URL + route.path,
    },
  ],
  script: !isDev
    ? [
        {
          hid: 'gtm',
          children: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NTWGKMLZ');`,
        },
      ]
    : [],
}));

if (route.query.lang !== undefined) {
  i18n.global.locale.value = 'en';
} else {
  i18n.global.locale.value = 'nl';
}

const travelAgencySchema = {
  '@context': 'https://schema.org',
  '@type': 'TravelAgency',
  name: 'Ecco Italia',
  url: 'https://www.eccoitalia.nl',
  telephone: '0108208521',
  email: 'customerservice@joyforitaly.com',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'NL',
    addressLocality: 'Rotterdam',
    addressRegion: 'Zuid-Holland',
    postalCode: '3044 EE',
    streetAddress: 'Overschieseweg 10',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.2',
    reviewCount: '113',
  },
};

useSchemaOrg(travelAgencySchema);
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
}

.titleLogo {
  width: 115px;
  height: 40px;
}
</style>
