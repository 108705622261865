export default {
  overview: {
    title: 'Accommodation portal',
    showAll: 'Show all',
    select: 'Select',
    search: 'Search',

    table: {
      id: 'id',
      accommodationName: 'Accommodation',
      units: 'Units',
    },
  },

  detail: {
    delete: 'Delete',
    confirmDelete: 'Are you sure you want to delete this booking?',
    invalidDate: 'Invalid selection',
    confirmDeleteTitle: 'Delete booking',
    addBooking: 'Add booking',
    block: 'Block',
    comment: 'Comment',
    start: 'From date: ',
    end: 'To date: ',
    periodUpdated: 'Period updated',
  },
};
