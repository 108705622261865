<template>
  <div class="row AanbodView">
    <div class="row justify-center full-width">
      <q-card class="q-pb-xl q-px-xl">
        <AccomodationFilters
          class="AccomodationFilters full-width"
          @open-modal="($event) => (showOverlay = $event)"
          is-searching
          fluid
          :show-reset="false"
          @search-clicked="handleSearchClick($event, true)"
          @reset-clicked="handleReset"
        />
      </q-card>
    </div>

    <div class="verfijnFiltersContainer q-my-lg col-12">
      <AanbodVerfijnFiltersDisplay
        class="q-pl-md"
        @open-filter="showVerfijnFilters = true"
        @updated-filters="applyFilters"
        :active-filters="activeFilters"
        @remove-search="removeSearch"
      >
        <template #lastSlide>
          <div class="flex items-end resetButtonContainer">
            <q-btn
              flat
              class="resetButton"
              @click="handleReset"
            >
              <q-icon
                name="icon-filter"
                size="18px"
                class="q-mr-sm"
              />
              Reset filters
            </q-btn>
          </div>
        </template>
      </AanbodVerfijnFiltersDisplay>
      <div class="row col-2 col-xs-12 q-gutter-md">
        <TextInput
          outlined
          v-model="filterData.name"
          debounce="1000"
          @update:model-value="updateSearch"
        >
          <template #prepend>
            <q-icon
              size="14px"
              name="icon-search"
            />
          </template>
        </TextInput>

        <q-btn
          color="primary-dark"
          outline
        >
          Sorteren
          <span v-if="activeSearchPreference">
            {{ isMobile ? '' : `&nbsp; op ${activeSearchPreference?.label?.toLowerCase()}` }}
          </span>
          <q-icon name="icon-sort" />
          <q-menu>
            <q-list>
              <q-item
                v-for="option in SEARCH_PREFERENCE_OPTIONS"
                class="q-mr-md"
              >
                <q-item-section>
                  <q-radio
                    v-model="filterData.searchPreference"
                    :val="option.value"
                  >
                    {{ option.label }}
                  </q-radio>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>

    <!-- For mobile -->
    <div ref="mobileMapSection">
      <client-only v-if="viewMobileMap && !isLoading && isMounted">
        <MapComponent
          class="q-ml-sm mobileMapContainer"
          :points="dataPoints"
          :is-loading="isLoading"
        />
      </client-only>
    </div>

    <div
      v-if="showOverlay"
      class="overlay"
    />
    <!-- <div class="amountResults row col-12 textLowImportance q-pt-md q-mt-md" v-if="hasVillas"></div> -->

    <div
      class="row col-12 q-col-gutter-sm"
      v-if="!isLoading"
    >
      <div
        class="col-12 col-sm-12"
        :class="isAdvice ? 'col-md-12' : 'col-md-8 '"
      >
        <div class="row items-end q-gutter-sm"></div>
        <div v-if="hasVillas">
          <AanbodVilla
            v-for="(villa, idx) in villaData"
            :key="idx"
            :villa="villa"
            :is-advice="isAdvice"
            :is-saved="isFavorited(villa.id)"
            @save-clicked="handleFavorite(villa.id, villa.name)"
            @add-clicked="handleAdviceUpdate(villa)"
          >
            <slot name="villaExtra" />
          </AanbodVilla>

          <div class="flex justify-center">
            <q-pagination
              v-if="maxPages > 1"
              v-model="filterData.page"
              :disable="isLoading"
              class="pagination"
              :max="maxPages"
              direction-links
              flat
              rounded
              color="black"
              active-color="black"
              :max-pages="6"
              boundary-numbers
              @update:model-value="scrollToMobileMapSection"
            />
          </div>
        </div>
        <div
          class="col-12 col-sm-8"
          v-else-if="!hasVillas"
        >
          Geen accommodaties gevonden met deze data
        </div>
      </div>

      <!-- Showing on bigger then mobile -->
      <div
        class="col-4 gt-sm"
        v-if="!isAdvice && isMounted"
      >
        <client-only>
          <MapComponent
            class="q-ml-sm mapComponentContainer"
            :points="dataPoints"
            :is-loading="isLoading"
          />
        </client-only>
      </div>
    </div>
    <div
      class="col-12 col-sm-8 loadingSpinner"
      v-else-if="isLoading"
    >
      <q-spinner size="200px" />
    </div>

    <AanbodVerfijnFilters
      v-model="showVerfijnFilters"
      v-if="showVerfijnFilters"
      @set-filters="applyFilters"
      :active-filters="activeFilters"
    />

    <q-btn
      v-if="isMobile && !viewMobileMap"
      color="primary"
      class="floatingViewMapButton"
      @click="openMobileMap"
    >
      <q-icon
        name="icon-map"
        size="sm"
        class="q-mr-sm"
      />
      Bekijk kaart
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import useStore from '~/store/store';
import { ACCOMODATION_TYPES, ACCOMODATION_SUB_FILTERS, HOLIDAY_TYPES, FEATURES, QUALITY_LABELS, LABELS, DISCOUNTS } from '~/config';
import type { PropType } from 'vue';
import { SEARCH_PREFERENCE_OPTIONS } from '~/config';

const isMobile = getIsMobile();

const props = defineProps({
  initialFilterData: {
    type: Object as PropType<VerfijnFilterValues>,
    required: false,
  },

  initialSearchData: {
    type: Object as PropType<SearchValueAvailability>, //only location...
    required: false,
  },

  isAdvice: {
    type: Boolean,
    default: false,
  },

  changeUrlParams: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const searchData = ref<SearchValueAvailability>();
const response = ref({});
const isMounted = ref(false);
const { gtag } = useGtag();
const route = useRoute();

const filterPayload = computed(() => {
  const payload = {
    location: searchData.value?.location,

    guests: searchData.value?.guests,
  };

  if (searchData.value?.date.length) {
    payload.dateRange = {
      useFlexible: false,
      static: {
        fromDate: searchData.value.date[0],
        toDate: searchData.value.date[1],
      },
    };
  }

  return { ...payload, ...filterData.value };
});

// Filtering voor het knopje 'Filters', holidayTypes, accommodationTypes etc.
const { filterData, showVerfijnFilters, isLoading, applyFilters, activeFilters, pushSearchParams } = getAanbodFilteringHandler(
  props.initialFilterData
);
initializePage();
const { handleFavorite, isFavorited } = favoriteHandler();
const viewMobileMap = ref(false);
const mobileMapSection = ref();

function handleAdviceUpdate(accommodatie: Accommodation) {
  const payload = {
    accommodation: accommodatie,
    ...store.guestData,
    fromDate: store.dateRangeData[0],
    toDate: store.dateRangeData[1],
    unit: null,
  };
  store.addCurrentAdviceAccomodations(payload);
  setToast({
    type: 'positive',
    message: 'Toegevoegd aan advies',
  });
}

function handleSearchClick(newVals: SearchValueAvailability, reset = false) {
  searchData.value = newVals;
  if (filterData.value && reset) {
    filterData.value.page = 1;
  }
  getData();
  pushSearchParams();
}

function handleReset() {
  store.resetAllFilters();
  // TODO: Find a better way to do this, maybe just make a base modal or something.
  filterData.value[HOLIDAY_TYPES] = [];
  filterData.value[QUALITY_LABELS] = [];
  filterData.value[LABELS] = [];
  filterData.value[DISCOUNTS] = [];
  filterData.value[ACCOMODATION_TYPES] = [];
  filterData.value[ACCOMODATION_SUB_FILTERS] = [];
  filterData.value[FEATURES] = [];

  activeFilters.value[HOLIDAY_TYPES] = [];
  activeFilters.value[QUALITY_LABELS] = [];
  activeFilters.value[LABELS] = [];
  activeFilters.value[DISCOUNTS] = [];
  activeFilters.value[ACCOMODATION_TYPES] = [];
  activeFilters.value[ACCOMODATION_SUB_FILTERS] = [];
  activeFilters.value[FEATURES] = [];

  searchData.value = {
    date: [],
  };
  removeSearch();
  filterData.value.page = 1;

  pushSearchParams();
}

const showOverlay = ref(false);

const villaData = computed(() => response.value?.accommodations);
const pagination = computed<{ showAmount: 0; totalItems: 0 }>(() => response.value?.pagination);
const hasVillas = computed(() => !!villaData.value?.length);
const maxPages = computed(() => Math.ceil(pagination.value?.totalItems / pagination.value?.showAmount));
const dataPoints = computed(() =>
  villaData.value?.map((item) => ({
    lat: item.latitude.replace('°', ''),
    long: item.longitude.replace('°', ''),
    name: item.name,
    slug: item.slug,
    region: [item.region, item.province].filter((item) => !!item).join(' ,'),
    imageUrl: item.thumbnailImageUrl,
  }))
);

const activeSearchPreference = computed(() => {
  return SEARCH_PREFERENCE_OPTIONS.find((item) => item.value === filterData.value.searchPreference);
});

async function updateSearch(searchValue: string) {
  filterData.value.name = searchValue;
  activeFilters.value.name = searchValue;
  filterData.value.page = 1;
}

function removeSearch() {
  filterData.value.name = '';
  activeFilters.value.name = '';
  filterData.value.page = 1;
}

function openMobileMap() {
  viewMobileMap.value = !viewMobileMap.value;
  scrollToMobileMapSection();
}

function scrollToMobileMapSection() {
  setTimeout(() => {
    if (mobileMapSection.value) {
      mobileMapSection.value.scrollIntoView({ behavior: 'smooth' });
    }
  }, 1);
}

onMounted(() => {
  isMounted.value = true;
});

async function getData() {
  isLoading.value = true;
  try {
    const data = (await fetchData('/accommodation/availability', { method: 'post', body: filterPayload.value })) as Accommodations;
    response.value = data;
    if (!filterData.value.page) filterData.value.page = 1;
    if (!props.isAdvice && data?.accommodations?.length) {
      nextTick(() => {
        gtag('event', 'view_item_list', {
          items: data.accommodations.map((item) => ({
            item_id: item.id,
            item_name: item.name,
          })),
        });
      });
    }
  } catch (e) {
    response.value = {};
  }

  isLoading.value = false;
}

function getAanbodFilteringHandler(initialFilterData: VerfijnFilterValues) {
  const router = useRouter();

  const filterData = ref(initialFilterData);
  const activeFilters = ref(filterData.value);
  const showVerfijnFilters = ref(false);
  const isLoading = ref(false);

  watch(
    filterData,
    () => {
      pushSearchParams();
      getData();
    },
    { deep: true }
  );

  async function applyFilters(newFilters: VerfijnFilterRecord) {
    filterData.value.page = 1;
    filterData.value[HOLIDAY_TYPES] = newFilters[HOLIDAY_TYPES]?.map((item: Filter) => item.value);
    filterData.value[QUALITY_LABELS] = newFilters[QUALITY_LABELS]?.map((item: Filter) => item.value);
    filterData.value[LABELS] = newFilters[LABELS]?.map((item: Filter) => item.value);
    filterData.value[DISCOUNTS] = newFilters[DISCOUNTS]?.map((item: Filter) => item.value);
    filterData.value[ACCOMODATION_TYPES] = newFilters[ACCOMODATION_TYPES]?.map((item: Filter) => item.value);
    filterData.value[ACCOMODATION_SUB_FILTERS] = newFilters[ACCOMODATION_SUB_FILTERS]?.map((item: Filter) => item.value);
    filterData.value[FEATURES] = newFilters[FEATURES]?.map((item: Filter) => item.value);
    filterData.value.minPrice = newFilters.minPrice;
    filterData.value.maxPrice = newFilters.maxPrice;

    store.activeFilters = filterData.value;
    activeFilters.value = newFilters;
    pushSearchParams();
    showVerfijnFilters.value = false;
  }

  function pushSearchParams() {
    if (props.isAdvice || !props.changeUrlParams) return;
    const dateObj = searchData.value?.date.length
      ? {
          startDate: searchData.value.date[0],
          endDate: searchData.value.date[1],
        }
      : {};
    const payload = {
      ...filterData.value,
      ...searchData.value?.location,
      ...dateObj,
      ...searchData.value?.guests,
    };
    router.push(`?${configureSearchParams(payload)}`);
  }

  return {
    filterData,
    showVerfijnFilters,
    isLoading,
    applyFilters,
    activeFilters,
    pushSearchParams,
  };
}

function initializePage() {
  onMounted(() => {
    if (store.activeFilters) store.activeFilters[ACCOMODATION_SUB_FILTERS] = [];

    if (props.initialFilterData?.[ACCOMODATION_SUB_FILTERS] && props.initialFilterData?.[ACCOMODATION_TYPES]) {
      store.activeFilters[ACCOMODATION_TYPES] = props.initialFilterData[ACCOMODATION_TYPES].map((item) => parseInt(item));
      store.activeFilters[ACCOMODATION_SUB_FILTERS] = props.initialFilterData[ACCOMODATION_SUB_FILTERS].map((item) => parseInt(item));
    }
  });

  if (props.initialFilterData) {
    setFiltersFromUrl(props.initialFilterData);
  }

  if (props.initialSearchData?.location?.regionIds?.length || props.initialSearchData?.location?.environmentIds?.length) {
    store.updateRegionData(props.initialSearchData?.location);
  }

  if (props.initialSearchData?.date) {
    store.updateDateRangeData(props.initialSearchData?.date);
  }

  if (props.initialSearchData?.guests) {
    Object.assign(store.guestData, props.initialSearchData?.guests);
  }

  const searchPayload = {
    date: store.dateRangeData,
    location: store.regionData || props.initialSearchData?.location,
    guests: store.guestData,
  };

  // Force call at the start
  handleSearchClick(searchPayload);

  // Stel de 'Verfijn filters' in die binnen komen uit de URL
  function setFiltersFromUrl(queryParams: VerfijnFilterValues) {
    const stringFilters = ['page', 'name'];
    const newFilters: Record<string, Filter[] | string[] | number[]> = {};

    for (const [key, value] of Object.entries(queryParams)) {
      if (stringFilters.includes(key)) {
        newFilters[key] = value;
      } else if (key === ACCOMODATION_SUB_FILTERS) {
        const newFiltersSet: string[] = [];
        if (value?.length) {
          const chosenAccoType = queryParams[ACCOMODATION_TYPES][0];
          const accoTypeFind = store.filters[ACCOMODATION_TYPES].find((item: Filter) => item.value.toString() === chosenAccoType);
          if (!accoTypeFind?.subFilters) return;
          value.forEach((filterId: number) => {
            newFiltersSet.push(accoTypeFind.subFilters.find((item: Filter) => item.value.toString() === filterId));
          });
          newFilters[key] = newFiltersSet;
        }
      } else {
        const newFiltersSet: string[] = [];
        if (value?.length && store.filters[key]) {
          value.forEach((filterId: number) => {
            newFiltersSet.push(store.filters[key].find((item: Filter) => item.value.toString() === filterId));
          });
        }
        newFilters[key] = newFiltersSet;
      }
    }
    activeFilters.value = newFilters;
  }
}

const runtimeConfig = useRuntimeConfig();
// Misschien ook de pagina als title doen zodat deze niet static lijkt?
useHead(() => {
  const next =
    filterData.value.page < maxPages.value
      ? {
          rel: 'next',
          href: runtimeConfig.public.siteUrl + route.path + '?page=' + (filterData.value.page + 1),
        }
      : undefined;

  const prevPage = filterData.value.page > 2 ? `?page=${filterData.value.page - 1}` : '';

  const prev =
    filterData.value?.page > 1
      ? {
          rel: 'prev',
          href: runtimeConfig.public.siteUrl + route.path + prevPage,
        }
      : undefined;
  const canonicalValue =
    filterData.value?.page > 1
      ? runtimeConfig.public.siteUrl + route.path + '?page=' + filterData.value?.page
      : runtimeConfig.public.siteUrl + route.path;

  const meta = route.fullPath.includes('&') ? { hid: 'robots', name: 'robots', content: 'noindex' } : undefined;

  return {
    link: [
      {
        rel: 'canonical',
        href: canonicalValue,
      },

      next ? next : '',
      prev ? prev : '',
    ],
    meta,
  };
});
</script>

<style lang="scss" scoped>
@import '../styles/variables.sass';

.AccomodationFilters {
  // min-width: 1200px;
  width: 100%;
  padding-top: 32px;
}

.AanbodView {
  max-width: 2000px;
  width: 100%;
  margin: auto;
}

:deep(.verfijnFiltersContainer) {
  .resetButtonContainer {
    height: 100%;
  }
  .resetButton {
    padding: 3px 24px !important;
  }
}

:deep(.pagination) {
  .q-btn {
    padding: 3px 2px !important;
    width: 30px !important;
    height: 30px !important;
    margin: 0 8px;
  }
}

.amountResults {
  display: none;
}

.loadingSpinner {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verfijnFiltersContainer {
  display: flex;
  align-items: flex-end;
}

.overlay {
  transition: 1s;
}

.overlay::before {
  transition: 1s;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background: rgba(18, 53, 91, 0.2);
  backdrop-filter: blur(16px);
  z-index: 9;
}

.mapComponentContainer {
  position: sticky;
  top: 80px;
}

.mobileMapContainer {
  display: none;
}

@media (max-width: $screen-lg) {
  .verfijnFiltersContainer {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: $screen-xs) {
  .AccomodationFilters {
    padding-top: 12px;
  }

  .amountResults {
    float: right;
    display: initial;
    color: $text-medium;
    border-top: $slate-blue 1px solid;

    .results {
      float: right;
    }
  }

  .floatingViewMapButton {
    position: fixed;
    bottom: 80px;
    right: 24px;
    box-shadow: 0px 1px 1px 0px rgba(55, 55, 55, 0.2), 0px 4px 9px 1px rgba(0, 0, 0, 0.2);
    z-index: 9;
  }

  .mobileMapContainer {
    display: block;
    width: 100vw;
    max-height: 70vh;
    z-index: 1;
    margin-left: -16px;
    margin-right: -16px;
  }

  :deep(.verfijnFiltersContainer) {
    .resetButton {
      padding: 8px !important;
    }
  }
}
</style>
