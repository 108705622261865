import { defineStore } from 'pinia';
import italyRegions from '~/assets/maps/italy-regions.json';
import * as topojson from 'topojson-client';
import useAuthStore from './auth';
import dayjs from 'dayjs';

export default defineStore('globalStore', () => {
  const authStore = useAuthStore();

  const { filters, availableFilters, filterAdminOptions, activeFilters, setAvailableFilters } = filterHandler();
  const {
    guestData,
    formattedGuests,
    totalAmountGuests,
    dateRangeData,
    updateDateRangeData,
    regionData,
    updateRegionData,
    topoJsonRegions,
    regionsMapping,
    environmentFilters,
    environmentMapping,
    setRegionOptions,
  } = travelDataFilterHandler();
  const { favorites, getFavorite, addFavorite, deleteFavorite, version, getVersion } = favoriteHandler();

  // TODO: use or remove
  function resetAllFilters() {
    const baseGuests = {
      adults: 2,
      children: 0,
      bedrooms: 0,
      babies: 0,
      allowSofaBeds: false,
    };
    Object.assign(guestData, baseGuests);
    dateRangeData.splice(0);
    regionData.value = { regionIds: [], environmentIds: [] };
    activeFilters.value = {};
  }
  const isMobile = ref(false);

  const currentAdviceAccomodations = ref<Travel[]>([]);

  function addCurrentAdviceAccomodations(value: Travel) {
    const item = value;
    item.comment = '';
    // item.id = 'localid-' + currentAdviceAccomodations.value.length + 1;

    // TODO: make sure duplicate acco/id can't be added.
    // if (!currentAdviceAccomodations.value.map((item) => item.accommodation.id ).includes(value.id)) {
    currentAdviceAccomodations.value.push(item);
    return true;
    // }

    // return false;
  }

  function removeCurrentAdviceAccomodation(accoId: number, unitId?: number) {
    if (!unitId) {
      const idx = currentAdviceAccomodations.value.findIndex((item) => item.accommodation.id === accoId);
      if (idx > -1) currentAdviceAccomodations.value.splice(idx, 1);
    } else {
      const idx = currentAdviceAccomodations.value.findIndex((item) => item.accommodation.id === accoId && item.unit?.id === unitId);
      if (idx > -1) currentAdviceAccomodations.value.splice(idx, 1);
    }
  }

  function setIsMobile(value: boolean) {
    isMobile.value = value;
  }

  /*
  Filters van 'Verfijn filters'
  */
  function filterHandler() {
    const availableFilters = ref([]);

    async function setAvailableFilters(filters?: Filter[]) {
      availableFilters.value = filters;
    }

    const accommodationTypesFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2170)?.filters)
    );
    const typesFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2171)?.filters)
    );

    const labelsFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2248)?.filters)
    );

    const discountFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2250)?.filters)
    );

    const facilitiesFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2207)?.filters)
    );

    const qualityLabelsFilters = computed<Filter[]>(() =>
      filterAdminOptions(availableFilters.value.find((item: Filter) => item.value === 2172)?.filters)
    );

    const filters = computed<Record<string, Filter[]>>(() => ({
      accommodationTypes: accommodationTypesFilters.value,
      holidayTypes: typesFilters.value,
      qualityLabels: qualityLabelsFilters.value,
      labels: labelsFilters.value,
      discounts: discountFilters.value,
      features: facilitiesFilters.value,
      accommodationSubFilters: [],
    }));

    const activeFilters = ref<VerfijnFilterValues>({});

    function filterAdminOptions(options: Filter[]) {
      // options = options.filter((item) => item.show);

      if (!authStore.user?.isAdmin) {
        options = options?.filter((item) => !item.adminOnly);
      }
      return options;
    }

    return { filters, availableFilters, filterAdminOptions, activeFilters, setAvailableFilters };
  }

  /*
  Filters van reis data, regio's, aantal gasten, aankomst- en vertrekdata
  */
  function travelDataFilterHandler() {
    const guestData = reactive({
      adults: 2,
      children: 0,
      bedrooms: 0,
      babies: 0,
      allowSofaBeds: false,
    });

    const formattedGuests = computed(() => guestsFormatter(guestData.adults, guestData.children, guestData.babies, guestData.bedrooms));
    const totalAmountGuests = computed(() => guestData.adults + guestData.children + guestData.babies);
    const dateRangeData = reactive<Date[] | string[]>([]);
    function compareDateWithYesterday(date: Date) {
      return new Date(date).getTime() < new Date().getTime() - 86400;
    }

    function updateDateRangeData(dateRange: Date[]) {
      if (!dateRange.length || compareDateWithYesterday(dateRange[0]) || compareDateWithYesterday(dateRange[1])) {
        dateRangeData.splice(0);
        return;
      }
      if (dayjs(dateRange[0]).isValid() && dayjs(dateRange[1]).isValid()) {
        dateRangeData[0] = toUtcAndStripTimeFormat(dateRange[0]);
        dateRangeData[1] = toUtcAndStripTimeFormat(dateRange[1]);
      }
    }

    const regionData = ref<{ regionIds: number[]; environmentIds: number[] }>({
      regionIds: [],
      environmentIds: [],
    });

    function updateRegionData(value: { regionIds: number[]; environmentIds: number[] }) {
      regionData.value = value;
    }

    const environmentFilters = ref([]);
    const topoJsonRegions = computed(() => topojson.feature(italyRegions, italyRegions.objects.ITA_adm1));
    const regionsMapping = computed(() => {
      const map = {};
      topoJsonRegions.value?.features.forEach((region) => {
        map[region.properties.ID_1] = region.properties.NAME_1;
      });
      return map;
    });

    const environmentMapping = computed(() => {
      const map = {};
      environmentFilters.value.forEach((environment) => {
        map[environment.value] = environment.label;
      });
      return map;
    });

    async function setRegionOptions(options: Filter[]) {
      if (!options.length) return;

      const regionFilters = options?.find((item) => item.value === 2185);

      if (regionFilters) {
        italyRegions.objects.ITA_adm1.geometries.forEach((region) => {
          const idx = regionFilters.filters.findIndex((filter) => filter.value === region.properties.ID_1);
          if (idx === -1) {
            region.show = false;
            region.properties.show = false;
          } else {
            region.properties.show = true;
            region.properties.NAME_1 = regionFilters.filters[idx].label;
            region.show = true;
          }
        });
      }

      const environments = options?.find((item) => item.value === 2187);
      if (environments) environmentFilters.value = environments?.filters;
    }

    return {
      topoJsonRegions,
      regionsMapping,
      guestData,
      formattedGuests,
      dateRangeData,
      updateDateRangeData,
      regionData,
      updateRegionData,
      environmentFilters,
      environmentMapping,
      totalAmountGuests,
      setRegionOptions,
    };
  }

  function favoriteHandler() {
    // TODO: buttons disablen wanneer het al in de favorites lijst staat.
    const favorites = ref<Accommodation[]>([]);
    const user = computed(() => authStore.user);
    const loggedIn = isLoggedIn();
    const version = ref();
    const { gtag } = useGtag();

    async function getFavorite() {
      const headers = {
        Authorization: `bearer ${authStore.token}`,
      };
      useClientFetch('/favorite', { headers }).then((res: Accommodation[]) => (favorites.value = res));
    }

    async function addFavorite(accommodationId: number, name: string) {
      if (authStore.isBookingUser) {
        setToast({
          message: 'Verifieer je e-mail zodat je je favorieten kan opslaan',
        });
      } else if (!loggedIn.value) {
        setToast({
          message: 'Log eerst in zodat je je favorieten kan opslaan',
        });
      } else {
        const response = (await useClientFetch('/favorite', {
          method: 'post',
          body: {
            accommodationId,
          },
        })) as { success: boolean };

        if (response?.success) {
          setToast({
            type: 'positive',
            message: 'Accommodatie toegevoegd aan favorieten',
          });
          gtag('event', 'add_to_wishlist', {
            items: [
              {
                item_id: accommodationId,
                item_name: name,
              },
            ],
          });
        }

        getFavorite();
      }
    }

    async function deleteFavorite(accommodationId: number) {
      await fetchData('/favorite', {
        method: 'delete',
        body: {
          accommodationId,
        },
      });

      setToast({
        type: 'positive',
        message: 'Accommodatie verwijderd uit favorieten',
      });

      getFavorite();
    }

    async function getVersion() {
      const headers = {
        Authorization: `bearer ${authStore.token}`,
      };
      useClientFetch('/version', { headers }).then((res) => {
        // TOOD: Deze geeft nog issues, gewoon ergens anders heen zetten. Component ofzo.
        const config = useRuntimeConfig();
        version.value = { front: config.public.version, back: res?.productVersion, environment: res.environment };
      });
    }

    return {
      favorites,
      getFavorite,
      addFavorite,
      deleteFavorite,
      version,
      getVersion,
    };
  }

  return {
    isMobile,
    setIsMobile,
    filters,
    availableFilters,
    filterAdminOptions,
    totalAmountGuests,
    currentAdviceAccomodations,
    addCurrentAdviceAccomodations,
    removeCurrentAdviceAccomodation,
    topoJsonRegions,
    regionsMapping,
    guestData,
    formattedGuests,
    dateRangeData,
    updateDateRangeData,
    setAvailableFilters,
    setRegionOptions,
    environmentMapping,
    regionData,
    updateRegionData,
    resetAllFilters,
    activeFilters,
    environmentFilters,

    favorites,
    getFavorite,
    version,
    addFavorite,
    deleteFavorite,
    getVersion,
  };
});
