<template>
  <Swiper
    class="swiperFilterContainer VerfijnFiltersDisplay"
    :modules="[SwiperNavigation, SwiperA11y, SwiperFreeMode]"
    :free-mode="true"
    :slides-per-view="'auto'"
    :space-between="12"
    navigation
  >
    <swiper-slide>
      <div class="filterButtonContainer">
        <div>
          <q-btn
            color="primary-dark"
            outline
            @click="emit('open-filter')"
            :class="{ iconButton: isMobile }"
            class="filterButton"
          >
            <q-icon
              name="icon-filter"
              size="18px"
              class="q-mr-sm"
            />
            <span> Alle filters </span>
            <q-badge
              class="amountFilterBadge"
              floating
              rounded
              v-if="amountFilters"
              v-text="amountFilters"
            />
          </q-btn>
        </div>
      </div>
    </swiper-slide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[LABELS]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.labels'"
          v-if="idx === 0"
        />
        <div class="filterSectionContainer">
          <q-chip
            outline
            class="chip selected"
            clickable
            @click="removeFilter(item, LABELS)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[DISCOUNTS]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.labels'"
          v-if="idx === 0 && !activeFilters[LABELS]?.length"
        />
        <div class="filterSectionContainer">
          <q-chip
            outline
            class="chip selected"
            clickable
            @click="removeFilter(item, DISCOUNTS)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide v-if="activeFilters.name">
      <div class="column">
        <div>Naam</div>
        <div class="filterSectionContainer">
          <q-chip
            class="selected"
            clickable
            outline
            @click="emit('remove-search')"
          >
            <q-icon name="icon-close" />

            {{ activeFilters.name }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <!-- <SwiperSlide v-if="activeFilters.maxPrice">
      <div class="column">
        <div class="filterSectionContainer">
          <div>Prijs</div>
          <q-chip
            class="selected"
            clickable
            outline
            @click="removePriceFilter"
          >
            <q-icon name="icon-close" />

            {{ priceLabel }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide> -->

    <SwiperSlide
      v-for="(item, idx) in activeFilters[ACCOMODATION_TYPES]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.accomodationType'"
          v-if="idx === 0"
        />
        <div class="filterSectionContainer">
          <q-chip
            clickable
            class="selected"
            @click="removeFilter(item, ACCOMODATION_TYPES)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[ACCOMODATION_SUB_FILTERS]"
      :key="idx"
    >
      <div class="column">
        <div class="filterSectionContainer">
          <q-chip
            clickable
            class="selected"
            @click="removeFilter(item, ACCOMODATION_SUB_FILTERS)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[QUALITY_LABELS]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.qualityLabel'"
          v-if="idx === 0"
        />
        <div class="filterSectionContainer">
          <q-chip
            outline
            class="chip selected"
            clickable
            @click="removeFilter(item, QUALITY_LABELS)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[HOLIDAY_TYPES]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.typeHoliday'"
          v-if="idx === 0"
        />
        <div class="filterSectionContainer">
          <q-chip
            clickable
            class="selected"
            @click="removeFilter(item, HOLIDAY_TYPES)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide
      v-for="(item, idx) in activeFilters[FEATURES]"
      :key="idx"
    >
      <div class="column">
        <div
          v-t="'landing.filters.facilities'"
          v-if="idx === 0"
        />
        <div class="filterSectionContainer">
          <q-chip
            outline
            class="chip selected"
            clickable
            @click="removeFilter(item, FEATURES)"
          >
            <q-icon name="icon-close" />
            {{ item?.label }}
          </q-chip>
        </div>
      </div>
    </SwiperSlide>

    <SwiperSlide>
      <slot name="lastSlide" />
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { HOLIDAY_TYPES, ACCOMODATION_TYPES, FEATURES, LABELS, QUALITY_LABELS, DISCOUNTS, ACCOMODATION_SUB_FILTERS } from '~/config';

const props = defineProps({
  activeFilters: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['open-filter', 'updated-filters', 'remove-search']);

const amountFilters = computed(() => countVerfijnFilters(props.activeFilters));
const isMobile = getIsMobile();
const accoTypeFilters = computed(() => props.activeFilters[ACCOMODATION_TYPES]);

watch(
  accoTypeFilters,
  (val) => {
    if (!val.length) {
      props.activeFilters[ACCOMODATION_SUB_FILTERS]?.forEach((filter: Filter) => {
        removeFilter(filter, ACCOMODATION_SUB_FILTERS);
      });
    }
  },
  { deep: true }
);

function removeFilter(item: Filter, type: string) {
  const currentFilters = props.activeFilters[type];
  const newCurrentFilters = currentFilters.filter((filter: Filter) => filter.value !== item.value);
  const newActiveFilters = {
    ...props.activeFilters,
  };
  newActiveFilters[type] = newCurrentFilters;
  emit('updated-filters', newActiveFilters);
}

// Price on hold TBC.
// const priceLabel = computed(() => `€${props.activeFilters.minPrice},- t/m  €${props.activeFilters.maxPrice},-`);
// function removePriceFilter() {
//   delete props.activeFilters.minPrice;
//   delete props.activeFilters.maxPrice;
//   emit('updated-filters', props.activeFilters);
// }
</script>

<style lang="scss" scoped>
@import '../../styles/variables.sass';

.filterButton {
  padding: 3px 24px;
}

.swiper {
  display: inline-block;
  margin: 0;
  width: 100%;

  .swiper-slide {
    height: auto;
    // margin: 0 10px;

    .filterButtonContainer {
      position: relative;
      height: 100%;
      display: flex;
      align-items: flex-end;
    }

    .filterSectionContainer .q-chip {
      margin: 0;
    }

    .column {
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }

    &.alleTypesSlide {
      display: inline-flex;
      align-items: flex-end;
      margin-bottom: 12px;
      padding: 0 20px;
    }
  }
}

:deep(.filterTypeList) {
  .q-chip {
    width: 100%;

    .q-chip__content {
      justify-content: center;
    }
  }
}
</style>
