import { defineStore } from 'pinia';
import { LANDING_PAGE_ID, ABOUT_PAGE_ID, QUICKLINKS_PAGE_ID, FOOTER_LINKS_PAGE_ID } from '~/config';

interface Redirect {
  destination: string;
  permanent: boolean;
  source: string;
}

export default defineStore('elocusStore', () => {
  const { landing, footerLinks, quickLinks, about, slugsDatabase, setLanding, setQuickLinks, setAbout, setFooterLinks, setDestinations } =
    footerLinksHandler();

  const databank = ref({});

  const redirects = ref<Redirect[]>([]);
  const redirectTriggers = computed(() => redirects.value.map((item: any) => item?.source));
  const destinations = ref<FilterDestinationsItem[]>([]);

  function setRedirects(res: Redirect[]) {
    redirects.value = res;
  }

  function setDatabank(value: any) {
    databank.value = value;
  }

  function footerLinksHandler() {
    const landing = ref<ElocusPage[]>([]);
    const quickLinks = ref<ElocusPage[]>([]);
    const about = ref<ElocusPage[]>([]);
    const footerLinks = ref<ElocusPage[]>([]);

    // Soms doet een gebruiker een starting slash als slug, die worden hier gestript
    function stripStartingSlashes(pages: ElocusPage[]) {
      pages?.forEach((page: { content: { slug: string } }) => {
        if (page?.content?.slug[0] === '/') {
          page.content.slug = page.content.slug.slice(1);
        }
      });
      return pages;
    }

    async function setLanding(res?: ElocusPage[]) {
      landing.value = stripStartingSlashes(res);
    }

    async function setQuickLinks(res: ElocusPage[]) {
      quickLinks.value = stripStartingSlashes(res);
    }

    async function setAbout(res: ElocusPage[]) {
      about.value = stripStartingSlashes(res);
    }

    async function setFooterLinks(res: ElocusPage[]) {
      footerLinks.value = stripStartingSlashes(res);
    }

    function setDestinations(res: FilterDestinationsItem[]) {
      res.forEach((item: any) => {
        item.landingPages = item.landingPages.map((page: any) => {
          page.url = `/bestemmingen/${page.slug}`;
          page.sitemapId = item.sitemapId;
          return page;
        });
      });
      destinations.value = res.flatMap((item: any) => item.landingPages);
    }

    const slugsDatabase = computed(() => {
      const obj: Record<string, string> = {};
      landing.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${LANDING_PAGE_ID}/${item._id}`));
      quickLinks.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${QUICKLINKS_PAGE_ID}/${item._id}`));
      about.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${ABOUT_PAGE_ID}/${item._id}`));
      footerLinks.value?.forEach((item: ElocusPage) => (obj['/' + item.content.slug] = `${FOOTER_LINKS_PAGE_ID}/${item._id}`));
      destinations.value?.forEach((item: any) => (obj[item.url] = `${item.sitemapId}/${item.id}`));

      return obj;
    });

    return { quickLinks, about, landing, footerLinks, slugsDatabase, setLanding, setQuickLinks, setAbout, setFooterLinks, setDestinations };
  }

  return {
    databank,
    setDatabank,
    redirects,
    setRedirects,
    redirectTriggers,
    quickLinks,
    about,
    setLanding,
    setDestinations,
    destinations,
    setQuickLinks,
    setAbout,
    setFooterLinks,
    landing,
    slugsDatabase,
    footerLinks,
  };
});
