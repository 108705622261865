import { joinURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL = '' } = {}) => {
  if (!baseURL) {
    baseURL = useRuntimeConfig().public.apiBase + '/api/media/';
  }

  if (!modifiers.height && modifiers.width) {
    modifiers.height = modifiers.width / 2;
  }

  return {
    url: joinURL(baseURL, src + '?width=' + modifiers.width + '&height=' + modifiers.width),
  };
};
