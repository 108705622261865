<template>
  <div class="">
    <div class="heading2 q-mb-lg">
      {{ content.titel }}
    </div>

    <div class="row q-col-gutter-lg">
      <div
        v-for="rel in displayablePages"
        class="col-xs-12 col-sm-6 col-md-3 link"
        :key="rel._id"
      >
        <router-link
          v-if="rel.content && rel.content.slug"
          :to="addStartingSlash(rel.content.slug)"
        >
          {{ rel.content.titel }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<GerelateerdePaginasContentblock>,
    required: true,
  },
});

const pages = computed(() => props.content.landingspaginaS.split(', '));

const related = await Promise.all(pages.value.map(async (id: number) => (await fetchData(`/api/page/2075/${id}`)) as ElocusPage[]));
const displayablePages = computed<ElocusPage[]>(() => related.filter((rel) => rel.content));

function addStartingSlash(slug: string): string {
  return slug.startsWith('/') ? slug : `/${slug}`;
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: underline;

  a {
    color: $text-regular;
  }
}
</style>
