<template>
  <!-- TODO: think of better name for component-->
  <q-card class="row ShoppingCartItemWideVariant">
    <div class="col-xs-12 col-sm-6">
      <div>
        <div class="q-mb-sm headingSmall q-mb-md">Accommodatie</div>
        <div class="row">
          <Image
            :src="accommodation.thumbnailImageUrl"
            class="col-3"
          />
          <div class="col-8">
            <div class="q-ml-md">
              <div
                class="heading4 accommodationName"
                v-text="accommodation.name"
              />
              <div
                class="textLowImportance location"
                v-text="address"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="data col-xs-12 col-sm-3 q-gutter-y-md">
      <div class="subItemContainer">
        <div class="headingSmall q-mb-md">Verblijf</div>
        <div>{{ unit.name }}</div>
      </div>
    </div>

    <div class="data col-xs-12 col-sm-3 q-gutter-y-md">
      <div class="subItemContainer">
        <div class="headingSmall q-mb-md">Data</div>
        <div>{{ tripDate }}</div>
        <div class="q-mt-md">{{ unit.guests?.length || unit.requestedNumberOfGuests }} gasten</div>
      </div>
    </div>

    <slot />
  </q-card>
</template>

<script setup lang="ts">
const props = defineProps({
  accommodation: {
    type: Object,
    required: true,
  },

  unit: {
    type: Object,
    default: () => {},
  },
});

const tripDate = computed(() => `${dayMonthFormat(props.unit.fromDate)} - ${dayMonthYearFormat(props.unit.toDate)}`);
const address = computed(() => {
  return [props.accommodation.region, props.accommodation.muncipality].filter((item) => !!item).join(', ');
});
// const guests = guestsFormatter(props.unit.numberOfAdults, props.unit.numberOfChildren, props.unit.numberOfBabies);
</script>

<style lang="scss" scoped>
.ShoppingCartItemWideVariant {
  padding: 24px;
  border-radius: 16px;
  width: 100%;

  > * {
    border-right: 2px solid $sea-blue-300;

    &:last-child {
      border-right: none;
    }
  }

  .subItemContainer {
    padding: 0 24px;
  }
}

.accommodationName {
  color: $calm-blue-500;
}

.location {
  color: $sea-blue-400;
}

@media (max-width: $screen-xs) {
  .ShoppingCartItemWideVariant {
    > * {
      border-right: none;
    }

    .subItemContainer {
      padding-left: 0;
      padding-top: 12px;
    }
  }
}
</style>
