import useElocusStore from '~/store/elocus';
import { LANDING_PAGE_ID, ABOUT_PAGE_ID, QUICKLINKS_PAGE_ID, FOOTER_LINKS_PAGE_ID } from '~/config';

export default defineNuxtPlugin(async () => {
  const elocusStore = useElocusStore();

  await fetchData('/redirect').then((res: Redirect[]) => {
    elocusStore.setRedirects(res);
  });

  fetchData('/api/pages/' + LANDING_PAGE_ID, {}).then((res: Page[]) => {
    elocusStore.setLanding(res);
  });

  fetchData('/api/pages/' + ABOUT_PAGE_ID, {}).then((res: Page[]) => {
    elocusStore.setAbout(res);
  });

  fetchData('/api/pages/' + QUICKLINKS_PAGE_ID, {}).then((res: Page[]) => {
    elocusStore.setQuickLinks(res);
  });

  fetchData('/api/pages/' + FOOTER_LINKS_PAGE_ID, {}).then((res: Page[]) => {
    elocusStore.setFooterLinks(res);
  });

  fetchData('/filter/destination/all', {}).then((res: FilterDestinationsItem[]) => {
    elocusStore.setDestinations(res);
  });
});
